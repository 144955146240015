import React from "react"
import { Layout } from "@components/layout"
import {
  wrapper,
  wrapper__container,
  wrapper__container__button,
} from "./styles/rodo.module.scss"
import Divider from "../../components.refactored/standard/Home/icons/leaves.svg"
import { AnimatedSection } from "@components/shared"

const Regulations = ({ pageContext }) => {
  const { page } = pageContext

  return (
    <Layout {...page}>
      <AnimatedSection className={wrapper}>
        <h1>Regulamin sklepu</h1>
        <img src={Divider} />
        <div className={wrapper__container}>
          <p>Polityka prywatności</p>
          <a
            href="http://cannable.api.localhost-group.com/wp-content/uploads/2021/01/polityka-prywatnosci.pdf"
            className={wrapper__container__button}
            target="_blank"
          >
            Kliknij i zobacz
          </a>
        </div>
        <div className={wrapper__container}>
          <p>Regulamin sklepu</p>
          <a
            href="http://cannable.api.localhost-group.com/wp-content/uploads/2021/01/regulamin-sklepu.pdf"
            className={wrapper__container__button}
            target="_blank"
          >
            Kliknij i zobacz
          </a>
        </div>
        <img src={Divider} />
      </AnimatedSection>
    </Layout>
  )
}

export default Regulations
